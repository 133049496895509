import React from 'react';

class Footer extends React.Component {
    render() {
        return (
            <div className="footer">
                coded with 🍻 and 🍕
            </div>
        );
    }
}

export default Footer
