import React from 'react';
import me1 from '../resources/me-1.png'
import me2 from '../resources/me-2.png'
import me3 from '../resources/me-3.png'
import me4 from '../resources/me-4.png'

var images = [me1, me2, me3, me4]

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = { imageNo: 0};
    }

    componentDidMount() {
        this.interval = setInterval(() => this.changeImage(), 10000);
    }

    changeImage() {
        const {imageNo} = this.state;
        const noOfImages = 4;

        let newCurrentImg = (imageNo + 1) % noOfImages;

        this.setState({imageNo: newCurrentImg});
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        return (
            <div className='container-header'>
                <div className='title-dark'>
                    <h1>Tudor Ulmeanu</h1>
                    <h2>Software Engineer in Manchester</h2>
                    <h3>hi@tudorulmeanu.co.uk</h3>
                </div>
                <div className='container-image'>
                    <img src={images[this.state.imageNo]} className='image'/>
                </div>
            </div>
        );
    }
}

export default Header