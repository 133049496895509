import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';

class Line extends React.Component {
    render() {
        return (
            <div className='changelist-div'>
                <CircleIcon style={{fontSize: '10px', paddingRight: '30px', color: `${this.props.color}`}}/>
                <p style={{marginRight: '70px', width: '150px', color: 'grey'}}>{this.props.date}</p>
                <p>{this.props.text} </p> <p style={{marginLeft: '.5rem', color: `${this.props.color}`}}>{this.props.colored}</p>
            </div>
        );
    }
}

export default Line