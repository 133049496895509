import React from 'react';
import Line from "./Line";
import {Grid} from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

class Changelog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {displayUpdates: false, displayChangelog: true};
    }

    triggerUpdates = () => {
        this.setState({displayUpdates: !this.state.displayUpdates});
    }

    triggerChangelog = () => {
        this.setState({displayChangelog: !this.state.displayChangelog});
    }

    render() {
        const dates = [
            {
                date: "Octo 2023",
                text: "Joined full-time at",
                highlight: "Flutter Entertainment Plc",
                color: "#00cc66"
            },
            {
                date: "Sept 2022",
                text: "Created this",
                highlight: "website",
                color: "#ff3385"
            },
            {
                date: "July 2021",
                text: "Joined full-time at",
                highlight: "Thoughtworks",
                color: "#00cc66"
            },
            {
                date: "June 2021",
                text: "Graduated",
                highlight: "University of Manchester",
                color: "#cc33ff"
            },
            {
                date: "July 2019",
                text: "Joined full-time at",
                highlight: "The Hut Group",
                color: "#00cc66"
            },
            {
                date: "Sept 2017",
                text: "Moved to",
                highlight: "Manchester",
                color: "#cc33ff"
            },
        ]

        return (<div className='changelogs'>
            {(!this.state.displayChangelog && <div className='changelogs-title'>
                <span onClick={this.triggerChangelog}>[+]</span>Changelog
            </div>)}
            {(this.state.displayChangelog && <div>
                <div className='changelogs-title'>
                    <span onClick={this.triggerChangelog}>[-]</span>Changelog
                </div>
                <div className='changelogs-text'>
                    A lot has happened over the past few years. Here's some highlights.
                </div>

                {dates.map(item =>
                    <Grid className="changelogs-grid" container direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
                        <Grid item>
                            <CircleIcon style={{fontSize: '10px', color: `${item.color}`}}/>
                        </Grid>

                        <Grid item>
                            <p style={{color: 'grey'}}>{item.date}</p>
                        </Grid>
                        <Grid item>
                            <p>{item.text}</p>
                        </Grid>
                        <Grid item style={{paddingLeft: 0, marginLeft: '0.5rem'}}>
                            <p style={{color: `${item.color}`}}>{item.highlight}</p>
                        </Grid>
                    </Grid>
                )}


                {/*</Grid>*/}

                {/*<Line date="September 2022" text="Created this" colored="website" color="#ff3385"/>*/}
                {/*<Line date="July 2021" text="Joined full-time at" colored="Thoughtworks" color="#00cc66"/>*/}
                {/*<Line date="June 2021" text="Graduated" colored="University of Manchester" color="#cc33ff"/>*/}
                {/*<Line date="July 2019" text="Joined full-time at" colored="The Hut Group" color="#00cc66"/>*/}
                {/*<Line date="September 2017" text="Moved to" colored="Manchester" color="#cc33ff"/>*/}
                {/*/!*<Line date="November 1998" text="I was" colored="born" color="#ff3385"/>*!/*/}
            </div>)}

            {(!this.state.displayUpdates && <div className='changelogs-title'>
                <span onClick={this.triggerUpdates}>[+]</span>Update/Releases
            </div>)}
            {(this.state.displayUpdates && <div>
                <div className='changelogs-title'>
                    <span onClick={this.triggerUpdates}>[-]</span>Update/Releases
                </div>
            </div>)}
        </div>);
    }
}

export default Changelog