import React from 'react';

class MainDarkPage extends React.Component {
    render() {
        return (
            <div className='description'>
                Ever since I was a child I had a passion for puzzle games. I loved the idea that multiple pieces without
                any separate meaning can form a whole new entity. Every piece of the puzzle had its own place and
                without one of them the puzzle can not be completed. Regardless of the size, or the shape that piece had
                its own role and without it the puzzle can never be completed. The same stands for programming: multiple
                parts of a program, connected with each other lead to the creation of a new application.
            </div>
        );
    }
}

export default MainDarkPage