import '../styles/main.css';
import MainPage from "./MainPage";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {ThemeContext, themes} from './Theme/ThemeContext';
import React, {Fragment} from "react";
import Switch from "@mui/material/Switch";
import Footer from "./Footer";

function App() {
    const [darkMode, setDarkMode] = React.useState(true);

    return (
        <Fragment>
            <div className='main'>
                <ThemeContext.Consumer>
                    {({changeTheme}) => (<Switch color="default" onClick={() => {
                        setDarkMode(!darkMode);
                        changeTheme(darkMode ? themes.light : themes.dark);
                    }}/>)}
                </ThemeContext.Consumer>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<MainPage/>}/>
                        <Route path="/changelog" element={<MainPage/>}/>
                        <Route path="*" element={<MainPage/>}/>
                    </Routes>
                </BrowserRouter>
                <Footer/>
            </div>
        </Fragment>
    );
}

export default App;
