import React, {Fragment} from 'react';
import Header from "./Header";
import Description from "./Description";
import Footer from "./Footer";
import Changelog from "./Changelog/Changelog";
import {Link} from "react-router-dom";
import {ExternalLink} from "react-external-link";
import {ThemeContext, themes} from "./Theme/ThemeContext";

import Switch from "@mui/material/Switch";

var color = [
    '#e9bc3f',
    '#ff99ff',
    '#00cc66',
    '#ff3385',
    '#1ad1ff'
]

class MainPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {displayDescription: true, displayChangelog: false, colors: color};
    }

    componentDidMount() {
        this.shuffle();
        this.interval = setInterval(() => this.shuffle(), 5000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    shuffle() {
        var array = this.state.colors;

        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        this.setState({colors: array});
    }

    switchPagesToDescription = () => {
        this.setState({displayDescription: true, displayChangelog: false});
    }

    switchPagesToChangelog = () => {
        this.setState({displayDescription: false, displayChangelog: true});
    }

    render() {
        return (
            <Fragment>
                <Header/>
                <div className='links'>
                    <Link onClick={this.switchPagesToDescription} to="/" style={{color: `${color[0]}`}}>description</Link>
                    <Link onClick={this.switchPagesToChangelog} to="/changelog" style={{color: `${color[1]}`}}>changelog</Link>
                    <ExternalLink href="https://resume.io/r/mETkjIfrH" style={{color: `${color[2]}`}}>profile</ExternalLink><br/><br/>
                    <ExternalLink href="https://www.instagram.com/ulmiiiii/" style={{color: `${color[3]}`}}>instagram</ExternalLink>
                    <ExternalLink href="https://www.linkedin.com/in/tudor-ulmeanu/" style={{color: `${color[4]}`}}>linkedin</ExternalLink>
                </div>
                {(this.state.displayDescription && <Description/>)}
                {(this.state.displayChangelog && <Changelog/>)}
            </Fragment>
        );
    }
}

export default MainPage